import React from 'react'

// ================= LIBRARIES ================= //
import { withTranslation } from 'react-i18next'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// ================= LIBRARIES ================= //

// ================= SERVICES ================= //
import { isAuthenticated } from './services/authentication'
// ================= SERVICES ================= //

// ================= COMPONENTS ================= //
import LoadingFallback from './components/loading_fallback'
// ================= COMPONENTS ================= //

// ================= STYLES ================= //
import './assets/styles/global.css'
import './assets/styles/helpers.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-crop/dist/ReactCrop.css'
import 'antd/dist/antd.css'
import 'mdbreact/dist/css/mdb.css'
// ================= STYLES ================= //

// ================= PAGES ================= //
const Login = React.lazy(() => import('./pages/public/login'))
const ForgotPassword = React.lazy(() => import('./pages/public/forgot-password'))
const RecoverPassword = React.lazy(() => import('./pages/public/recover-password'))
const ChangePassword = React.lazy(() => import('./pages/public/change-password'))
const ConfirmPassword = React.lazy(() => import('./pages/public/confirm-password'))
const Layout = React.lazy(() => import('./pages/private/layout'))
// ================= PAGES ================= //

// ================= PRIVATE ROUTE ================= //
const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
	  	{...rest}
	  	render={props =>isAuthenticated() ? (
			<Component {...props} />
		) : (
		  	<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
		)
	  }
	/>
)
// ================= PRIVATE ROUTE ================= //

class App extends React.Component{
	render(){
		return(
			<>
		
				<BrowserRouter>
					<ToastContainer />

					<React.Suspense fallback={<LoadingFallback />}>
						<Switch>
							<Route exact path="/login" render={props => <Login {...props}/>} />							
							<Route exact path="/recuperar-senha" render={props => <ForgotPassword {...props}/>} />		
							<Route exact path="/nova-senha" render={props => <RecoverPassword {...props} />} />					
							<Route exact path="/recuperar-senha-sucesso" render={props => <ChangePassword {...props} />} />	
							<Route exact path="/nova-senha-sucesso" render={props => <ConfirmPassword {...props} />} />	
							<PrivateRoute path="/" component={props => <Layout {...props}/>} />
						</Switch>
					</React.Suspense> 
				</BrowserRouter>
			</>
		)
	}
}


export default withTranslation()(App)