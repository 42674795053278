import React from 'react'
import Spinner from 'react-spinner-material'

class LoadingFallback extends React.Component{

    render(){
        return(
            <>
                <div className="loading-fallback">
                    <div className="loading-fallback-spinner">
                        <Spinner radius={80} color={'#fff'} stroke={6} visible={true} />
                    </div>
                </div>
            </>
        )
    }
}

export default LoadingFallback