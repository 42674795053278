import React, { createContext, useEffect } from 'react'
import useLocalStorage from './../hooks/useBrowserLocalStorage'
import i18n from './../locales/i18n'

const LocaleContext = createContext()

function LocaleProvider({children}) {
    const [savedLocale, saveLocale] = useLocalStorage('@SeagateCentral-lang',"en")

    const updateLocale = (locale) => { 
        saveLocale(locale)
    }
    
    useEffect(() => {
        i18n.changeLanguage(savedLocale)
    }, [savedLocale])



    return (
        <LocaleContext.Provider
            value={{
                savedLocale,
                updateLocale,
            }}
        >
        {children}
        </LocaleContext.Provider>
    )
}

export { LocaleProvider, LocaleContext as default }
