

export const isAuthenticated  = () => localStorage.getItem('@SeagateCentral-Token') !== null

export const getToken = () => localStorage.getItem('@SeagateCentral-Token')

export const login = token => {
    localStorage.setItem('@SeagateCentral-Token', token)
}

export const logout = () => {
    localStorage.removeItem('@SeagateCentral-Token')
}